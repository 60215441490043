export default {
  state: {
    userData: {},
  },

  getters: {
    getUserData(state) {
      return state.userData;
    },
  },

  mutations: {
    setUserData(state, data) {
      const isNull = data === null;

      state.userData = {
        id: !isNull ? data.id : "",
        name: !isNull ? data.name : "",
        unity_reference_id: !isNull ? parseInt(data.unity_reference_id) : "",
        email: !isNull ? data.email : "",
        phone: !isNull ? data.phone : "",
        cpf: !isNull ? data.cpf : "",
        profile: !isNull ? parseInt(data.profile_id) : "",
      };
    },
  },

  actions: {
    changeUserData(context, infos) {
      context.commit("setUserData", infos);
    },
  },
};
