export default {
  state: {
    allAbilities: [],
    selectedProfile: {},
    profileAbilitiesData: [],
    selectedProfileAbilitiesId: [],
  },

  getters: {
    getSystemAbilities(state) {
      return state.allAbilities;
    },

    getSelectedProfile(state) {
      return state.selectedProfile;
    },

    getProfileAbilities(state) {
      return state.profileAbilitiesData;
    },

    getSelectedProfileAbilitiesId(state) {
      return state.selectedProfileAbilitiesId;
    },
  },

  mutations: {
    setSystemAbilities(state, abilities) {
      state.allAbilities = abilities;
    },

    setSelectedProfile(state, item) {
      state.selectedProfile = item;
    },

    setProfileAbilitiesInfo(state, abilities) {
      state.profileAbilitiesData.push(abilities);
    },

    setSelectedProfileAbilitiesId(state, id) {
      state.selectedProfileAbilitiesId = id;
    },
  },

  actions: {
    changeSystemAbilities(context, abilities) {
      context.commit("setSystemAbilities", abilities);
    },

    changeSelectedProfile(context, item) {
      context.commit("setSelectedProfile", item);
    },

    changeProfileAbilitiesInfo(context, abilities) {
      context.commit("setProfileAbilitiesInfo", abilities);
    },

    changeSelectedProfileAbilitiesId(context, id) {
      context.commit("setSelectedProfileAbilitiesId", id);
    },
  },
};


/*
Este é um módulo de gerenciamento de estado do Vuex para um sistema de gerenciamento de perfis de usuários. 
Ele contém quatro propriedades de estado:

allAbilities: uma matriz vazia para armazenar todas as habilidades do sistema.
selectedProfile: um objeto vazio para armazenar o perfil de usuário selecionado.
profileAbilitiesData: uma matriz vazia para armazenar as habilidades do perfil de usuário selecionado.
selectedProfileAbilitiesId: uma matriz vazia para armazenar as IDs das habilidades do perfil de usuário selecionado.
Existem quatro getters para recuperar esses estados do Vuex.

Existem quatro mutations para atualizar os estados do Vuex. Cada mutação é responsável por 
atualizar uma propriedade de estado específica.

Existem quatro actions para chamar as mutações correspondentes. Cada action recebe um contexto e um 
argumento para atualizar a propriedade de estado específica.

*/