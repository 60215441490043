import Vue from "vue";
import Vuex from "vuex";

import notifications from "./modules/widgets/notifications";
import grids from "./modules/widgets/grids";
import modals from "./modules/widgets/modals";

import routes from "./modules/routesModule";
import captcha from "./modules/captchaModule";
import password from "./modules/passwordModule";
import account from "./modules/accountModule";
import user from "./modules/userModule";
import profiles from "./modules/profilesModule";
import origin from "./modules/originModule"
import cause from './modules/causeModule'
import diagnoses from './modules/diagnoses'
import resoluteness from './modules/resoluteness'
import procedures from "./modules/procedures";
import docutor from "./modules/docutorModule";
import provenance from "./modules/provenanceModule";
import event from "./modules/eventModule";
import postoModule from "./modules/postoModule";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    // WIDGETS
    notifications,
    grids,
    modals,

    // MODULES
    event,
    origin,
    provenance,
    procedures,
    docutor,
    postoModule,
    resoluteness,
    diagnoses,
    cause,
    routes,
    captcha,
    password,
    account,
    user,
    profiles,
  },
});
