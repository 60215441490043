export default {
  state: {
    defaultData: {},
  },

  getters: {
    getEventData(state) {
      return state.defaultData;
    },
  },

  mutations: {
    setEventData(state, data) {
      const isNull = data === null;

      state.defaultData = {
        id: !isNull ? data.id : "",
        name: !isNull ? data.name : "",
        year: !isNull ? data.year : "",
        date_start: !isNull ? data.date_start : "",
        date_end: !isNull ? data.date_end : "",
        county_reference_id: !isNull ? data.county_reference_id : "",
        unity: !isNull ? data.unity : [],
        health_centers: !isNull ? data.health_centers  : [],
        origins: !isNull ? data.origins  : [],
      };
    },
  },

  actions: {
    changeEventData(context, infos) {
      context.commit("setEventData", infos);
    },
  },
};
