import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store/index";
// import guard from "@/guard/routeGuard.js";

Vue.use(VueRouter);

const routes = [
  // LOGIN
  {
    path: "/",
    component: () => import("@/views/ViewWithoutLayout.vue"),
    children: [
      {
        path: "",
        name: "Login",
        component: () => import("@/components/modules/login/Login.vue"),
      },
    ],
  },

  // DASHBOARD
  {
    path: "/dashboard",
    component: () => import("@/views/ViewWithLayout.vue"),
    children: [
      {
        path: "",
        name: "Dashboard",
        permission: "dashboard",
        component: () => import("@/components/modules/dashboard/Dashboard.vue"),
      },
    ],
  },

  // CAD. BÁSICOS
  // PERFIS
  {
    path: "/perfis",
    component: () => import("@/views/ViewWithLayout.vue"),
    children: [
      {
        path: "",
        name: "Lista de perfis",
        permission: "list_perfil",
        component: () => import("@/components/modules/profiles/Grid.vue"),
      },
    ],
  },

  // USUARIOS
  {
    path: "/usuarios",
    component: () => import("@/views/ViewWithLayout.vue"),
    children: [
      {
        path: "",
        name: "Lista de Operador",
        permission: "list_usuario",
        component: () => import("@/components/modules/users/Grid.vue"),
      },
    ],
  },

  // MINHA CONTA
  {
    path: "/minha-conta",
    component: () => import("@/views/ViewWithLayout.vue"),
    children: [
      {
        path: "",
        name: "Minha Conta",
        permission: "account",
        component: () => import("@/components/modules/my-account/Account.vue"),
      },
    ],
  },

  // RESETAR SENHA
  {
    path: "/esqueci-minha-senha",
    component: () => import("@/views/ViewWithoutLayout.vue"),
    children: [
      {
        path: "",
        name: "Esqueci minha senha",
        component: () => import("@/components/modules/password/Password.vue"),
      },
    ],
  },
  {
    path: "/municipios",
    component: () => import("@/views/ViewWithLayout.vue"),
    children: [
      {
        path: "",
        name: "Consultar Municípios",
        component: () => import("@/components/modules/county/Grid.vue"),
      },
    ],
  },
  {
    path: "/unidades",
    component: () => import("@/views/ViewWithLayout.vue"),
    children: [
      {
        path: "",
        name: "Consultar Unidades",
        component: () => import("@/components/modules/unit/Grid.vue"),
      },
    ],
  },
  {
    path: "/origens",
    component: () => import("@/views/ViewWithLayout.vue"),
    children: [
      {
        path: "",
        permission: "list_origem",
        name: "Consultar Origem",
        component: () => import("@/components/modules/origin/Grid.vue"),
      },
    ],
  },
  {
    path: "/postos",
    component: () => import("@/views/ViewWithLayout.vue"),
    children: [
      {
        path: "",
        name: "Consultar Postos",
        permission: "list_posto",
        component: () => import("@/components/modules/post/Grid.vue"),
      },
    ],
  },
  {
    path: "/evento",
    component: () => import("@/views/ViewWithLayout.vue"),
    children: [
      {
        path: "",
        name: "Parametrização do evento",
        permission: "list_evento",
        component: () => import("@/components/modules/parameterization/Grid.vue"),
      },
    ],
  },
  {
    path: "/causa",
    component: () => import("@/views/ViewWithLayout.vue"),
    children: [
      {
        path: "",
        name: "Consultar Causa",
        permission: "list_causa",
        component: () => import("@/components/modules/cause/Grid.vue"),
      },
    ],
  },
  {
    path: "/diagnostico",
    component: () => import("@/views/ViewWithLayout.vue"),
    children: [
      {
        path: "",
        name: "Consultar Diagnóstico",
        permission: "list_diagnostico",
        component: () => import("@/components/modules/diagnosis/Grid.vue"),
      },
    ],
  },
  {
    path: "/resolutividade",
    component: () => import("@/views/ViewWithLayout.vue"),
    children: [
      {
        path: "",
        name: "Consultar Resolutividade",
        permission: "list_resolutividade",
        component: () => import("@/components/modules/resoluteness/Grid.vue"),
      },
    ],
  },
  {
    path: "/procedimento",
    component: () => import("@/views/ViewWithLayout.vue"),
    children: [
      {
        path: "",
        name: "Consultar Procedimento",
        permission: "list_procedimento",
        component: () => import("@/components/modules/procedures/Grid.vue"),
      },
    ],
  },
  {
    path: "/medico",
    component: () => import("@/views/ViewWithLayout.vue"),
    children: [
      {
        path: "",
        name: "Consultar Médico",
        permission: "list_profissional",
        component: () => import("@/components/modules/docutor/Grid.vue"),
      },
      {
        path: "escala/:id",
        name: "Consultar Médico",
        permission: "cad_escala",
        component: () => import("@/components/modules/docutor/Edit.vue"),
      },
    ],
  },
  {
    path: "/procedencia",
    component: () => import("@/views/ViewWithLayout.vue"),
    children: [
      {
        path: "",
        name: "Consultar Procedência",
        permission: "list_procedimento",
        component: () => import("@/components/modules/provenance/Grid.vue"),
      },
    ],
  },
  {
    path: "/ocorrencia",
    component: () => import("@/views/ViewWithLayout.vue"),
    children: [
      {
        path: "",
        name: "Consultar Ocorrência",
        permission: "list_ocorrencia",
        component: () => import("@/components/modules/ocorrencias/Grid.vue"),
      },
      {
        path: "editar/:id",
        name: "Editar Ocorrência",
        permission: "cad_ocorrencia",
        component: () => import("@/components/modules/ocorrencias/Default.vue"),
      },
      {
        path: "criar",
        name: "Criar Ocorrência",
        permission: "cad_ocorrencia",
        component: () => import("@/components/modules/ocorrencias/Default.vue"),
      },
    ],
  },
];

store.state.localRoutes = routes;

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes,
});

export default router;
