export default {
  state: {
    diagnosesData: {},
  },

  getters: {
    getDiagnosesData(state) {
      return state.diagnosesData;
    },
  },

  mutations: {
    setDiagnosesData(state, data) {
      const isNull = data === null;

      state.diagnosesData = {
        id: !isNull ? data.id : "",
        name: !isNull ? data.name : "",
      };
    },
  },

  actions: {
    changeDiagnosesData(context, infos) {
      context.commit("setDiagnosesData", infos);
    },
  },
};
