export default {
  state: {
    originData: {},
  },

  getters: {
    getOriginData(state) {
      return state.originData;
    },
  },

  mutations: {
    setOriginData(state, data) {
      const isNull = data === null;

      state.originData = {
        id: !isNull ? data.id : "",
        name: !isNull ? data.name : "",
        county_reference_id: !isNull ? data.county_reference_id  : "",
      };
    },
  },

  actions: {
    changeOriginData(context, infos) {
      context.commit("setOriginData", infos);
    },
  },
};
