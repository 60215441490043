export default {
  state: {
    defaultData: {},
  },

  getters: {
    getDocutorData(state) {
      return state.defaultData;
    },
  },

  mutations: {
    setDocutorData(state, data) {
      const isNull = data === null;

      state.defaultData = {
        id: !isNull ? data.id : "",
        name: !isNull ? data.name : "",
        identification_code: !isNull ? data.identification_code  : "",
      };
    },
  },

  actions: {
    changeDocutorData(context, infos) {
      context.commit("setDocutorData", infos);
    },
  },
};
