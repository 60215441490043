export default {
  state: {
    defaultData: {},
  },

  getters: {
    getResolutenessData(state) {
      return state.defaultData;
    },
  },

  mutations: {
    setResolutenessData(state, data) {
      const isNull = data === null;

      state.defaultData = {
        id: !isNull ? data.id : "",
        name: !isNull ? data.name : "",
      };
    },
  },

  actions: {
    changeResolutenessData(context, infos) {
      context.commit("setResolutenessData", infos);
    },
  },
};
