export default {
  state: {
    causeData: {},
  },

  getters: {
    getCauseData(state) {
      return state.causeData;
    },
  },

  mutations: {
    setCauseData(state, data) {
      const isNull = data === null;

      state.causeData = {
        id: !isNull ? data.id : "",
        name: !isNull ? data.name : "",
      };
    },
  },

  actions: {
    changeCauseData(context, infos) {
      context.commit("setCauseData", infos);
    },
  },
};
