export default {
  state: {
    searchData: {
      type: "",
      search: "",
    },

    actionsData: [],
    updateGrid: false,
    currentPage: 1, // Página atualmente exibida
  },

  getters: {
    getSearchData(state) {
      return state.searchData;
    },

    getActionsData(state) {
      return state.actionsData;
    },

    getUpdateGrid(state) {
      return state.updateGrid;
    },
  },

  mutations: {
    setSearchData(state, data) {
      state.searchData = {
        type: data.type,
        search: data.search,
      };
      state.currentPage = 1; // Reset da página para 1 ao atualizar o filtro de pesquisa
    },

    setActionsData(state, data) {
      state.actionsData = data;
    },

    setUpdateGridStatus(state, status) {
      state.updateGrid = status;
    },
  },

  actions: {
    changeSearchData(context, data) {
      context.commit("setSearchData", data);
    },

    changeActionsData(context, data) {
      context.commit("setActionsData", data);
    },

    changeUpdateGridStatus(context, status) {
      context.commit("setUpdateGridStatus", status);
    },
  },
};
